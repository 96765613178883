
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { InterestsService, InterestList, AppInterestList, InterestDescLists } from './interests.service'
import { ConfigService } from '@/services/config.service'
import { BridgeService } from '@/services/bridge.service'
import ProgressBar from './_components/progress-bar.vue'

/** 权益 */
export default defineComponent({
  name: 'Interests',
  components: { ProgressBar },
  setup () {
    const injector = useReflectiveInjector()
    const service = injector.get(InterestsService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const bridge = injector.get(BridgeService)

    const viewModel = reactive({
      os: '',
      title: config.getTitle,
      interestList: [] as AppInterestList[], /** 普通权益列表 */
    })

    /** 当前权益列表 */
    const currentInterestList = computed(() => {
      return viewModel.interestList.filter((_, i) => i < 1)
    })

    /** 超级会员权益总数 */
    const interesTotal = computed(() => {
      let total = 0

      currentInterestList.value.forEach(e => {
        total += e.interest_list.length
      })

      return total
    })

    onMounted(() => {
      service.requestDeviceInfo().then(({ os }) => {
        viewModel.os = os
      }).finally(() => {
        initInterest()
      })
    })

    /**
     * @description 初始化权益列表
     */
    function initInterest() {
      const params = { os: viewModel.os }

      service.requestInterest(params).then(res => {
        viewModel.interestList = res.app_interest_list
      })
    }

    /**
     * @description 处理点击权益调起
     */
    // eslint-disable-next-line complexity
    function clickInterestItem (item: InterestList) {
      if (!item.desc_key) {
        if (!item.is_start_up) {
          return
        }

        item.progess_bar = true

        setTimeout(() => {
          item.progess_bar = false
        }, 2000)

        callClientTop(item)
      }
    }

    /**
     * @description 上方权益卡牌调用客户端
     */
    function callClientTop (item: InterestList) {
      const { path, cmd } = item

      bridge.openKisProcess({ path, cmd })
    }

    /**
     * @description 下方权益描述调用客户端
     */
    function callClientBottom (item: InterestDescLists) {
      const { path, cmd } = item?.page_detail

      bridge.openKisProcess({ path, cmd })
    }

    return {
      viewModel,
      interesTotal,
      callClientBottom,
      clickInterestItem,
      currentInterestList,
    }
  }
})
