import { Injectable } from '@tanbo/vue-di-plugin'
import { Http } from '@zhfe/common-library'

export interface AvaterRecommendAvatarList {
  /** 头像id */
  id: string
  /** 头像url */
  url: string
}

export interface AvaterResponseData {
  /** 推荐头像列表 */
  recommend_avatar_list: AvaterRecommendAvatarList[]
}

export interface EditUserInfoRequestParams {
  /** 头像id */
  avatar_id: string
  /** 用户昵称 */
  name: string
}

@Injectable()
export class UserInfoDetailService {
  constructor(
    private http: Http
  ) {
  }

  /**
   * @description 请求头像列表
   */
  requestAvatarList () {
    return this.http.post<AvaterResponseData>('/account/recommend_avatar', {})
  }

  /**
   * @description 修改用户信息
   */
  requestEditUserInfo (params: EditUserInfoRequestParams) {
    return this.http.post('/account/modify_user_info', params)
  }
}
