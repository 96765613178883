
import { computed, defineComponent, onBeforeUnmount, reactive } from 'vue'
import {useReflectiveInjector} from '@tanbo/vue-di-plugin'
import QrcodeVue from 'qrcode.vue'
import {UserService} from '../../../services/user.service'
import {BridgeService} from '../../../services/bridge.service'
import { ToastService } from '@/services/toast.service'
import { PayQrcodeService } from './pay-qrcode.service'
import { PayPackages, PaySettingsService } from '@/views/pay/_components/pay-settings.service'
import {CookieService} from '@/services/cookie.service';

/** 支付类型&支付二维码 */
export default defineComponent({
  name: 'Pay',
  components: {
    QrcodeVue,
  },
  setup () {
    let isDestroy = false // 用于劫持异步
    const injector = useReflectiveInjector()
    const service = injector.get(PayQrcodeService)
    const paySetting = injector.get(PaySettingsService)
    const user = injector.get(UserService)
    const bridge = injector.get(BridgeService)
    const toast = injector.get(ToastService)
    const cookie = injector.get(CookieService)

    const viewModel = reactive({
      payType: 1, /** 支付类型 1：微信 2：支付宝 */
      qrText: '', /** 支付二维码 */
      orderId: '', /** 订单id */
      isVip: user.isVip, /** 是否未会员 */
      isLogin: user.isLogin, /** 是否登录 */
      isExpires: false, /** 支付二维码是否过期 */
      isPayGuide: false, /** 控制支付引导显示 */
      loopPayResultTimer: null, /** 轮询支付结果定时器 */
      paySettingItem: {} as PayPackages, /** 当前选中套餐项 */
    })

    /** 订阅当前选中套餐 */
    paySetting.onPaySettingToggle.subscribe(item => {
      viewModel.paySettingItem = item
      initPayQrCode()
    })

    /** 订阅用户信息变化 */
    // user.onUserInfoChanged.subscribe(() => {
    //   const assignmentField = ['isVip', 'isLogin']
    //   assignmentField.forEach((field) => (viewModel[field] = user[field]))
    // })

    /** 套餐id */
    const packageId = computed(() => viewModel.paySettingItem?.product_id)

    /** 当前金额 */
    const currentPrice = computed(() => (viewModel.paySettingItem?.page_detail?.current_fee))

    /** 优惠金额 */
    const discountPrice = computed(() => viewModel.paySettingItem?.page_detail?.discount_fee)

    /** 支付宝支付 */
    const isAliPay = computed(() => viewModel.payType === 2)

    /** 微信支付 */
    const isWeChatPay = computed(() => (viewModel.payType === 1))

    onBeforeUnmount(() => {
      isDestroy = true
      clearLoopOrderTimer()
    })

    /**
     * @description 设置支付类型
     */
    function setPayType (idx) {
      clearLoopOrderTimer()
      viewModel.payType = idx
      initPayQrCode()
    }

    /**
     * @description 初始化支付二维码
     */
    function initPayQrCode () {
      if (!viewModel.isLogin) return
      const payAction = {
        1: () => loadWeChatPayQrCode(),
        2: () => loadAliPayQrCode()
      }

      payAction[viewModel.payType]()
    }

    /**
     * @description 加载微信支付二维码
     */
    function loadWeChatPayQrCode () {
      const params = {
        payapp: cookie.productId,
        product_id: packageId.value,
        client_extra: {
          ...service.client_extra // TODO: 二期order_type会变多，一期先写死
        }
      }

      service.requestWeChatPayQrcode(params).then(res => {
        handleNormalQrcodeSuccess(res.wx_resp.code_url, res.order_id)
      }).catch(() => {
        payQrCodeLoadFail()
      })
    }

    /**
     * @description 加载支付宝支付二维码
     */
    function loadAliPayQrCode () {
      const params = {
        payapp: cookie.productId,
        product_id: packageId.value,
        return_url: '',
        client_extra: {
          ...service.client_extra // TODO: 二期order_type会变多，一期先写死
        }
      }

      service.requestAliPayQrcode(params).then(res => {
        handleNormalQrcodeSuccess(res?.ali_resp?.code_url, res?.order_id)
      }).catch(() => {
        payQrCodeLoadFail()
      })
    }

    /**
     * @description 处理成功的普通二维码
     */
    function handleNormalQrcodeSuccess (url:string, orderId: string) {
      viewModel.isExpires = false
      viewModel.qrText = url
      viewModel.orderId = orderId

      payOrderLoopAction()
    }

    /**
     * @description 支付二维码加载失败
     */
    function payQrCodeLoadFail () {
      toast.showToast({ message: '获取支付信息异常' })
    }

    /**
     * @description 点击默认二维码图片
     */
    function clickDefaultQrImg () {
      // 过期重新刷新支付二维码
      if (viewModel.isExpires) {
        initPayQrCode()
        return
      }

      bridge.userLogin()
    }

    /**
     * @description 订单轮询操作
     */
    function payOrderLoopAction () {
      clearLoopOrderTimer()

      let totalTime = 3600 // 轮询时长为1小时
      const time = 3 // 每隔3秒轮询一次

      viewModel.loopPayResultTimer = setInterval(() => {
        totalTime = totalTime - time

        if (totalTime > 0) {
          const params = {
            order_id: viewModel.orderId,
            payapp: cookie.productId
          }

          service.requestPayResult(params).then(res => {
            if (isDestroy) {
              clearLoopOrderTimer()
              return
            }

            if (res.order.status === 'paid') {
              handlePaySuccess()
            }
          })
        } else {
          clearLoopOrderTimer()
          viewModel.isExpires = true
        }
      }, time * 1000)
    }

    /**
     * @description 处理支付成功
     */
    async function handlePaySuccess () {
      clearLoopOrderTimer()
      bridge.refreshUserInfo() // 通知客户端刷新用户信息，用于同步客户端用户状态
      await user.requestUserInfo() // 更新前端用户信息
      toast.showToast({ message: '支付成功' })
    }

    /**
     * @description 清除轮询订单定时器标识
     */
    function clearLoopOrderTimer () {
      clearInterval(viewModel.loopPayResultTimer)
    }

    /**
     * @description 显示支付引导
     */
    function showPayGuide () {
      viewModel.isPayGuide = !viewModel.isPayGuide
    }

    return {
      isAliPay,
      viewModel,
      isWeChatPay,
      currentPrice,
      discountPrice,
      setPayType,
      showPayGuide,
      clickDefaultQrImg,
      handleLogin () {
        bridge.userLogin()
      },
    }
  }
})

