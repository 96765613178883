
import {defineComponent, computed, reactive } from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { UserService } from '../../services/user.service'
import { BridgeService } from '../../services/bridge.service'
import { UserInfoService } from './user-info.service'
import { login_type_info, ConfigService } from '@/services/config.service'

import UserInfoDetail from './_components/user-info-detail.vue'
import { CookieService } from '@/services/cookie.service'

/** 用户信息 */
export default defineComponent({
  name: 'UserInfo',
  components: { UserInfoDetail },
  setup() {
    const injector = useReflectiveInjector()
    const service = injector.get(UserInfoService)
    const user = injector.get(UserService)
    const bridge = injector.get(BridgeService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const cookie = injector.get(CookieService)

    const viewModel = reactive({
      renewStatus: false, /** 续费按钮点击状态 */
      detailVisible: false, /** 控制详情显示 */
      isVip: user.isVip,
      normalVIP: user.normalVIP,
      isDiamondVIP: user.isDiamondVIP,
      avatar: user.avatar,
      nickname: user.nickname,
      isLogin: user.isLogin,
      loignType: user.loignType,
      title: config.getTitle,
      memberDateText: user.memberDateText(),
      productId: cookie.productId,
    })

    /** 订阅用户信息变化 */
    user.onUserInfoChanged.subscribe(() => {
      const assignmentField = [
        'isVip',
        'normalVIP',
        'isDiamondVIP',
        'avatar',
        'isLogin',
        'nickname',
        'loignType',
      ]

      viewModel.renewStatus = false
      viewModel.memberDateText = user.memberDateText()
      assignmentField.forEach((field) => (viewModel[field] = user[field]))
    })

    /** 头部描述定制文案 */
    const headText = computed(() => {
      if (!viewModel.isLogin) { // 未登录
        return '登录并升级专业版，享受高级功能'
      }

      if (!viewModel.isVip) { // 非会员
        return '升级专业版，享受高级功能'
      }

      if (viewModel.normalVIP) {
        return `专业版已订阅至${viewModel.memberDateText}，继续购买将延长订阅时间`
      }

      if (viewModel.isDiamondVIP) {
        return '尊敬的用户，您已终身订阅专业版，永久享受特权'
      }

      return ''
    })

    /** 根据vip状态配置class, 这里为了兼容样式 */
    const heightClass = computed(() => {
      return 'hg_114'
    })

    /** 登录类型class */
    const loginTypeClass = computed(() => {
      const loginTypeMap = {
        [login_type_info.wechat]: 'login_type_wechat',
        [login_type_info.qq]: 'login_type_qq',
        [login_type_info.phone]: 'login_type_phone'
      }

      return loginTypeMap[viewModel.loignType]
    })

    /** 点击续费按钮 */
    function clickRenew() {
      service.onRenew.next()
      viewModel.renewStatus = true
    }

    /** 查看权益 */
    function viewInterest () {
      const { origin, search } = location
      bridge.openOptionWindow({
        url: `${origin}/memberInterestDetail${search}`,
        open_web: 'memberInterestDetail',
        width: 675,
        height: 540
      })
    }

    /** 点击登录 */
    function clickLogin() {
      bridge.userLogin()
    }

    /** 错误使用默认头像 */
    function errorImg() {
      viewModel.avatar = require('@/assets/images/not-login-avatar.png')
    }

    /**
     * @description 打开用户详情
     */
    function openUserDetail () {
      if (!viewModel.isLogin) {
        return
      }

      controlModalVisible(true)
    }

    /**
     * @description 对话框关闭处理
     */
    function modalCancel () {
      controlModalVisible(false)
    }

    /**
     * @description 对话框成功处理
     */
    function modalSuccess () {
      controlModalVisible(false)
    }

    /**
     * @description 控制对话框显示隐藏
     */
    function controlModalVisible(visible: boolean) {
      viewModel.detailVisible = visible
    }

    return {
      headText,
      viewModel,
      heightClass,
      loginTypeClass,
      errorImg,
      clickRenew,
      clickLogin,
      modalCancel,
      modalSuccess,
      viewInterest,
      openUserDetail,
    }
  },
})
