
import { defineComponent, reactive, onMounted } from 'vue'
import { useReflectiveInjector, Type } from '@tanbo/vue-di-plugin'
import { UserInfoDetailService } from './user-info-detail.service'
import { ConfigService, login_type_text_info } from '../../../services/config.service'
import { ToastService } from '../../../services/toast.service'
import { BridgeService } from '@/services/bridge.service'
import { UserService } from '@/services/user.service'

/** 用户详情 */
export default defineComponent({
  name: 'UserInfoDetail',
  setup(_, { emit }) {
    const injector = useReflectiveInjector([UserInfoDetailService])
    const service = injector.get(UserInfoDetailService)
    const config = injector.get(ConfigService as Type<ConfigService>)
    const toast = injector.get(ToastService)
    const bridge = injector.get(BridgeService)
    const user = injector.get(UserService)

    const viewModel = reactive({
      msg: '',
      avatarId: '',
      maxLength: 16,
      logo: config.getLogo,
      title: config.getTitle,
      openId: user.openId,
      avatar: user.avatar,
      loginType: user.loignType,
      nickname: user.nickname,
      oldName: user.nickname,
      vipInfo: user.vip_Info,
      isVip: user.isVip,
      memberDateText: user.memberDateText(),
      priorityMemberInfo: user.getPriorityMemberInfo,
      defaultAvatar: require('@/assets/images/not-login-avatar.png'),
      moreMemberModalVisible: false, /** 控制更多会员信息显示 */
      avatarList: [],
    })

    onMounted(() => {
      initAvatarList()
    })

    /**
     * @description 初始化头像列表
     */
    function initAvatarList() {
      service
        .requestAvatarList()
        .then((res) => (viewModel.avatarList = res.recommend_avatar_list))
    }

    /**
     * @description 提交修改用户信息
     */
    function submitEditUserInfo() {
      if (!viewModel.nickname.trim()) {
        viewModel.msg = '请填写您的昵称'
        return
      }

      const hasChangeAvatar = !!viewModel.avatarId
      const hasChangeName = viewModel.oldName !== viewModel.nickname

      if (!hasChangeName && !hasChangeAvatar) {
        handleUserInfoSuccess()
        return
      }

      const params = {
        avatar_id: viewModel.avatarId,
        name: viewModel.nickname,
      }

      service
        .requestEditUserInfo(params)
        .then(() => {
          viewModel.msg = ''
          bridge.refreshUserInfo() // 通知客户端刷新用户信息，用于同步客户端用户状态
          user.requestUserInfo() // 更新前端用户信息
          handleUserInfoSuccess()
        })
        .catch((err) => {
          const ret = err.responseCommon.ret
          const err_ret_info = {
            4452002: '用户昵称包含敏感词',
            4452001: '用户昵称过长',
          }
          const errMessage = err_ret_info[ret]

          if (errMessage) {
            viewModel.msg = errMessage
          } else {
            toast.showToast({ message: '服务端错误' })
          }
        })
    }

    /**
     * @description 选择用户头像
     */
    function selectAvatar(index) {
      const item = viewModel.avatarList[index]

      viewModel.avatarId = item.id
      viewModel.avatar = item.url
    }

    /**
     * @description 处理用户信息成功
     */
    function handleUserInfoSuccess() {
      toast.showToast({ message: '修改成功!' })

      setTimeout(() => {
        emit('success')
      }, 1000)
    }

    /**
     * @description 复制操作
     */
    function copyOpenId() {
      const aux = document.createElement('input')
      aux.value = viewModel.openId
      document.body.appendChild(aux)

      aux.select()

      document.execCommand('copy')
      document.body.removeChild(aux)
      toast.showToast({ message: '复制成功' })
    }

    /**
     * @description 输入规则校验
     */
    function checkInputRule() {
      // eslint-disable-next-line
      var regStr = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi;

      if (regStr.test(viewModel.nickname)) {
        viewModel.nickname = viewModel.nickname.replace(regStr, '')
        return
      }

      const nowName = viewModel.nickname
      let chNum = 0 // 中文个数
      let enNum = 0 // 英文个数

      const arr = (!!nowName && nowName.split('')) || [] // 字符串数组 ~ 处理长度为1的空字符串
      const isZh = (i) => isChinese(nowName.charCodeAt(i)) // 当前字符是否为中文
      const getNum = () => chNum + enNum; // 总个数
      const getSize = () => chNum * 2 + enNum // 总字符数
      const overLength = () => getSize() >= viewModel.maxLength // 是否超出长度
      const handleOverSize = () => getSize() === viewModel.maxLength + 1 // 剩1个字符位置可以输入中文

      arr.map((e, i) => {
        if (overLength()) {
          return e
        }

        isZh(i) ? chNum++ : enNum++

        return e
      })

      const valid = handleOverSize();
      viewModel.nickname = valid
        ? nowName.substr(0, getNum() - 1)
        : nowName.substr(0, getNum())
    }

    /**
     * @description 是否为中文
     */
    function isChinese(charCode) {
      return !(
        (charCode > 0x0001 && charCode <= 0x007e) ||
        (charCode >= 0xff60 && charCode <= 0xff9f)
      )
    }

    /**
     * @description 处理加载失败头像
     */
    function handleLoadFailAvatar() {
      viewModel.avatar = viewModel.defaultAvatar
    }

    /**
     * @description 关闭窗口
     */
    function closeWindow() {
      emit('cancel')
    }

    /**
     * @description 控制更会员信息展示
     */
    function controlMoreVipInfoVisible (visible: boolean) {
      viewModel.moreMemberModalVisible = visible
    }

    return {
      viewModel,
      closeWindow,
      copyOpenId,
      checkInputRule,
      selectAvatar,
      submitEditUserInfo,
      handleLoadFailAvatar,
      login_type_text_info,
      controlMoreVipInfoVisible
    }
  },
})
