import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "interests_title" }
const _hoisted_2 = { class: "bl_name" }
const _hoisted_3 = { class: "interests_list flex_start_center" }
const _hoisted_4 = {
  key: 0,
  class: "super_member"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.currentInterestList, (p, pIdx) => {
    return (_openBlock(), _createBlock("div", {
      class: "interests",
      key: pIdx
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode("span", _hoisted_2, _toDisplayString(p.name), 1)
      ]),
      _createVNode("ul", _hoisted_3, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(p.interest_list, (c, cIdx) => {
          return (_openBlock(), _createBlock("li", {
            key: cIdx,
            class: "interests_item flex_column_centers",
            onClick: ($event: any) => (_ctx.clickInterestItem(c))
          }, [
            (c?.page_detail?.is_super_member)
              ? (_openBlock(), _createBlock("i", _hoisted_4))
              : _createCommentVNode("", true),
            _createVNode("img", {
              src: c.img_url,
              alt: "",
              class: "common_interest_block"
            }, null, 8, ["src"]),
            _createVNode("span", null, _toDisplayString(c.title), 1),
            _withDirectives(_createVNode(_component_ProgressBar, null, null, 512), [
              [_vShow, c.progess_bar]
            ])
          ], 8, ["onClick"]))
        }), 128))
      ])
    ]))
  }), 128))
}