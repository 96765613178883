<template>
  <div class="progress-bar-wrap">
    <div class="progress-bar" />
  </div>
</template>

<style lang="scss" scoped>
.progress-bar-wrap {
  position: absolute;
  top: 4px;
  left: 2px;
  width: 76px;
  height: 4px;
  border-radius: 2px;
  background-color: #F6E7A5;
}

.progress-bar {
  width: 0;
  height: 100%;
  border-radius: 2px;
  background-color: #F7AE00;
  animation: progressAni 2s linear infinite;
}

@keyframes progressAni {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
