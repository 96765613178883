import {Injectable} from '@tanbo/vue-di-plugin'
import {ReportService} from '@/services/report.service'
import {CookieService} from '@/services/cookie.service';

@Injectable()
export class HomeService {
  report = this.reporter.makeReporter('waitui_52haoya_vip_paypage:375 show_from:string fun_from:string product_id:int version:short vip_version:short poptype:byte', {
    show_from: this.cookie.show_from,
    fun_from: this.cookie.fun_from,
    poptype: 1
  })

  constructor(
    private reporter: ReportService,
    private cookie: CookieService
  ) {
  }
}
