
import {defineComponent } from 'vue'
import { useReflectiveInjector } from '@tanbo/vue-di-plugin'
import { PayQrcodeService } from '@/views/pay/_components/pay-qrcode.service'
import { PaySettingsService } from '@/views/pay/_components/pay-settings.service'
import PayQrcode from './_components/pay-qrcode.vue'
import PaySettings from './_components/pay-settings.vue'

/** 支付 */
export default defineComponent({
  name: 'Pay',
  components: {
    PaySettings,
    PayQrcode,
  },
  setup() {
    useReflectiveInjector([PayQrcodeService, PaySettingsService])
  }
})
