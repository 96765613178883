
import {computed, defineComponent, reactive} from 'vue';
import {useReflectiveInjector, Type } from '@tanbo/vue-di-plugin';
import {UserService} from '../../services/user.service';
import {HeaderService} from './header.service'
import {ConfigService } from '@/services/config.service';
import {BridgeService} from '@/services/bridge.service';
import {CookieService} from '@/services/cookie.service';

export default defineComponent({
  name: 'Header',
  setup(_, context) {
    const injector = useReflectiveInjector([HeaderService]);
    const user = injector.get(UserService);
    const config = injector.get(ConfigService as Type<ConfigService>)
    const cookie = injector.get(CookieService)
    const bridge = injector.get(BridgeService)

    const viewModel = reactive({
      logo: config.getLogo,
      isLogin: user.isLogin,
      title: config.getTitle,
      productId: cookie.productId,
      headerQrCodeImg: config.getHeaderQrCodeImg
    })

    user.onLogout.subscribe(() => {
      viewModel.isLogin = user.isLogin
    });

    /** 是否展示兑换码 */
    const showExchange = computed(() => {
      return false
    })

    function clickLogout() {
      user.onLogout.next()
    }

    function clickClose() {
      // window.close()
      context.emit('close')
    }

    /**
     * @description 打开兑换码窗口
     */
    function clickExchange () {
      const { origin, search } = location
      bridge.openOptionWindow({
        url: `${origin}/exchange${search}`,
        open_web: 'exchange',
        width: 620,
        height: 440
      })
    }

    return {
      viewModel,
      showExchange,
      clickClose,
      clickLogout,
      clickExchange,
    };
  },
});
