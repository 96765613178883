import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_Pay = _resolveComponent("Pay")!
  const _component_Interests = _resolveComponent("Interests")!
  const _component_Coupon = _resolveComponent("Coupon")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_UserInfo, null, {
      default: _withCtx(() => [
        _createVNode(_component_Header, { onClose: _ctx.handleHeaderClose }, null, 8, ["onClose"])
      ]),
      _: 1
    }),
    _createVNode("div", {
      class: ['wrapper_scroll', _ctx.heightClass],
      ref: "scrollNode"
    }, [
      (_ctx.isPay)
        ? (_openBlock(), _createBlock(_component_Pay, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_Interests)
    ], 2),
    (_ctx.viewModel.isShowCoupon)
      ? (_openBlock(), _createBlock(_component_Coupon, {
          key: 0,
          onClose: _ctx.handleCouponClose,
          onPaysucc: _ctx.handleCouponPaySuccess,
          onLogin: _ctx.handleCouponLogin
        }, null, 8, ["onClose", "onPaysucc", "onLogin"]))
      : _createCommentVNode("", true)
  ]))
}